import React from "react"
import Layout from "../components/Layout/Layout"
import "./index.scss"
import reactIcon from "../images/react.png"
import hooksIcon from "../images/hooks.png"
import Kubernetes from "../images/kubernetes.png"
import laravel from "../images/laravel.png"
import mysql from "../images/mysql.png"
import reduxIcon from "../images/redux.png"
import sass from "../images/sass.png"
import docker from "../images/docker.png"
import doctrine from "../images/doctrine.png"
import { withPrefix, Link } from "gatsby"
import HelmetWrapper from "../components/HelmetWrapper/HelmetWrapper"

import email from "../images/email.png"
import github from "../images/github.png"
import linkedIn from "../images/linkedin.png"
import resume from "../images/resume.png"

const IndexPage = ({ location }) => {
  function renderIcon(iconName) {
    switch (iconName) {
      case "react": {
        return reactIcon
      }
      case "hooks": {
        return hooksIcon
      }
      case "redux": {
        return reduxIcon
      }
      case "sass": {
        return sass
      }
      case "docker": {
        return docker
      }
      case "kubernetes": {
        return Kubernetes
      }
      case "laravel": {
        return laravel
      }
      case "doctrine": {
        return doctrine
      }
      case "mysql": {
        return mysql
      }
      default: {
        return null
      }
    }
  }

  const featuredSkills = [
    { name: "React", code: "react" },
    { name: "Hooks", code: "hooks" },
    { name: "Redux", code: "redux" },
    { name: "Sass", code: "sass" },
    { name: "Docker", code: "docker" },
    { name: "Kubernetes", code: "kubernetes" },
    { name: "Laravel", code: "laravel" },
    { name: "Doctrine", code: "doctrine" },
    { name: "MySQL", code: "mysql" },
  ]

  let arr = [3, 4, featuredSkills.length - 2]

  return (
    <>
      <HelmetWrapper title="Home" />
      <Layout pathname={location.pathname}>
        <div className="home-page-container">
          <div className="profile-pic" />
          <div className="hello-blurb">
            <h1 className="center">Hello World!</h1>
            <p>My name is Jason, nice to meet you!</p>
            <p>
              I am a full stack developer based in Toronto, Canada. I have with experience in:
            </p>
            <div className="skills-list">
              <div className="skill-row">
                {featuredSkills.map((featuredSkill, idx) => {
                  if (idx <= 4) {
                    return (
                      <p
                        className="skill-item"
                        key={featuredSkill.code}
                        id={idx === 3 || idx === 4 ? "no-show-mobile" : ""}
                      >
                        <img
                          src={renderIcon(featuredSkill.code)}
                          alt={featuredSkill.name}
                          height={25}
                        />{" "}
                        {featuredSkill.name}
                      </p>
                    )
                  }
                  return null
                })}
              </div>
              <div className="skill-row mobile-only">
                {featuredSkills.map((featuredSkill, idx) => {
                  if (arr.includes(idx)) {
                    return (
                      <p className="skill-item" key={featuredSkill.code}>
                        <img
                          src={renderIcon(featuredSkill.code)}
                          alt={featuredSkill.name}
                          height={25}
                        />{" "}
                        {featuredSkill.name}
                      </p>
                    )
                  }
                  return null
                })}
              </div>
              <div className="skill-row short">
                {featuredSkills.map((featuredSkill, idx) => {
                  if (idx > 4) {
                    return (
                      <p
                        className="skill-item"
                        key={featuredSkill.code}
                        id={
                          idx === featuredSkills.length - 2
                            ? "no-show-mobile"
                            : ""
                        }
                      >
                        <img
                          src={renderIcon(featuredSkill.code)}
                          alt={featuredSkill.name}
                          height={25}
                        />{" "}
                        {featuredSkill.name}
                      </p>
                    )
                  }
                  return null
                })}
              </div>
            </div>
            <p>
              I'm currently learning about AWS Lambda functions, and
              exploring Blockchain to pursue my interests outside of web / mobile
              development.
            </p>
            <p>
              For a rundown on my projects, check out{" "}
              <a
                rel="noopener noreferrer"
                href={withPrefix("/JasonLeeResume.pdf")}
                target="_blank"
                className="resume-link"
              >
                my resume
              </a>{" "}
              and{" "}
              <Link to={"/portfolio"} className="portfolio-link">
                my portfolio!
              </Link>
            </p>
            <div className="spacer" />
            <div className="contact-options">
              <a
                target="_blank"
                href="https://www.linkedin.com/in/wlee367"
                rel="noopener noreferrer"
              >
                <img src={linkedIn} alt="email" height={40} />
              </a>
              <a
                target="_blank"
                href="https://www.github.com/wlee367"
                rel="noopener noreferrer"
              >
                <img src={github} alt="github" height={40} />
              </a>
              <a
                rel="noopener noreferrer"
                href={withPrefix("/JasonLeeResume.pdf")}
                target="_blank"
              >
                <img src={resume} alt="resume" height={40} />
              </a>
              <a href="mailto:proto.rhee@gmail.com">
                <img src={email} alt="email" height={40} />
              </a>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default IndexPage
